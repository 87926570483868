import { useEffect } from "react";
import { Box, Button, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import {
    responsiveFontSizes,
    ThemeProvider,
    useTheme
} from '@mui/material/styles';
import { Link as HyperLink } from 'react-router-dom';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Banner from "./banner";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";
import Slide1 from "../../assets/slides/1.jpg";
import Slide2 from "../../assets/slides/2.jpg";
import Slide3 from "../../assets/slides/3.jpg";
import Slide4 from "../../assets/slides/4.jpg";
import Slide5 from "../../assets/slides/5.jpg";
import Slide6 from "../../assets/slides/6.jpg";
import Slide7 from "../../assets/slides/7.jpg";
import Slide8 from "../../assets/slides/8.jpg";
import Slide9 from "../../assets/slides/9.jpg";
import Slide10 from "../../assets/slides/10.jpg";
import Slide11 from "../../assets/slides/11.jpg";
import Slide12 from "../../assets/slides/12.jpg";
import Slide13 from "../../assets/slides/13.jpg";
import Slide14 from "../../assets/slides/14.jpg";
import ImgDistribute from "../../assets/bg/distribute.jpg";
import CaltexLogo from "../../assets/logos/CaltexH.png";
import ShellLogo from "../../assets/logos/shell-lube-logo.png";
import MobilLogo from "../../assets/logos/mobile-lube-logo.png";
import TasBlueLogo from "../../assets/logos/tasblueLogo.png";
import OilchemLogo from "../../assets/logos/oilchemLogo.png";
import NKLogo from "../../assets/logos/NKLogo.png";

import LocalShippingIcon from '@mui/icons-material/LocalShippingTwoTone';
import OilBarrelIcon from '@mui/icons-material/OilBarrelTwoTone';
import CardMembershipIcon from '@mui/icons-material/CardMembershipTwoTone';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStationTwoTone';

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const matchesXL = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesL = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));

    const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7, Slide8, Slide9, Slide10, Slide11, Slide12, Slide13, Slide14];

    const slideCount = (() => {
        if (matchesMD) {
            return 1;
        } else if (matchesL) {
            return 2;
        } else if (matchesXL) {
            return 2;
        } else {
            return 3;
        }
    });

    const sliderStyle = {
        objectFit: 'cover',
        height: '400px !important',
        width: '100%'
    }

    const bgImgStyle = {
        backgroundImage: `url(${ImgDistribute})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '50% 50%', paddingBottom: '48px'
    }

    const logoGridStyle = { 
        textAlign: 'center', 
        margin: 'auto' 
    }

    return (
        <>
            <Banner />
            <ThemeProvider theme={theme}>
                <Box sx={{
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '24px',
                    paddingBottom: '32px',
                    margin: '0'
                }}>
                    <Container maxWidth="lg">
                        <Typography variant="h3" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            marginTop: '16px',
                            marginBottom: '32px',
                            color: '#004090',
                            fontFamily: 'home-caption'
                        }}>
                            TAS PETROLEUM
                        </Typography>
                        <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            marginTop: '32px',
                            color: '#555',
                            fontSize: '22px',
                            fontWeight: '300',
                        }}>
                            Fueling Tasmania's Potential: Pioneering Independent Distribution of Fuel, AdBlue®, and Lubricants for a Thriving Future
                        </Typography>
                        <Box>
                            <Button variant="contained" className="animate__animated animate__fadeInUp" sx={{
                                marginTop: '32px'
                            }} size="large" component={HyperLink} to="/about">Learn more about us</Button>
                        </Box>
                    </Container>
                </Box>
                <Swiper
                    slidesPerView={slideCount()}
                    spaceBetween={0}
                    loop={true}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}>

                    {slides.map((item, index) => (
                        <SwiperSlide key={index} >
                            <Box sx={sliderStyle} component="img" alt="Logo" src={item} />
                        </SwiperSlide>
                    ))}
                </Swiper>
                <Grid container spacing={2}>
                    <Grid item md={12} sx={{
                        width: '100%',
                        background: '#004090',
                        color: 'white'
                    }}>
                        <Container maxWidth="lg" sx={{ paddingTop: '48px' }}>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                    WE KEEP THE STATE RUNNING
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                    textAlign: 'justify'
                                }}><br />
                                    We deliver quality Fuels, Lubricants and AdBlue® state-wide and offer a wide range of products
                                    to meet the needs of all industries, from Automotive, Agricultural, Industrial, Transport, Civil, Mining, Marine and Aviation.
                                    <br /><br />Our Fuels, Lubricants, Solvents and AdBlue® are the gold standard for performance and reliability. We offer competitive prices and
                                    exceptional customer service, so you can be sure you're getting the best possible value for your money.
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Button variant="contained" sx={{
                                    textAlign: 'center',
                                    marginTop: '32px',
                                    marginBottom: '48px'
                                }} size="large" component={HyperLink} to="/products">Our products & services</Button>
                            </AnimationOnScroll>
                        </Container>
                    </Grid>
                    <Grid item md={12} sx={bgImgStyle}>
                        <Container maxWidth="lg" sx={{ marginTop: '48px', color: 'white' }}>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                    fontWeight: '300'
                                }}>
                                    OUR TEAM
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                    marginTop: '48px',
                                    textAlign: 'justify'
                                }}><br />
                                    Our team is made up of friendly, flexible, reliable, and local professionals who are dedicated to providing our
                                    customers with the best possible service. We have a strong focus on customer service, brand quality, and safety.
                                    We run a modern tanker fleet operated by a team of experienced drivers, whose local knowledge is pivotal to ensuring customers' needs are met.
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                    fontWeight: '300',
                                    marginTop: '48px'
                                }}>
                                    ENERGIZING FUEL INDUSTRY
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                    marginTop: '48px',
                                    textAlign: 'justify',
                                }}><br />
                                    Ignite Your Journey in Tasmania's Fuel Industry.
                                    Established in 2005, our heritage and commitment deliver premium bulk fuel.
                                    Proudly serving thriving farming communities and diverse commercial fuel needs.
                                    Experience the driving force of Tasmania's fuel landscape with reliability and excellence.
                                </Typography>
                            </AnimationOnScroll>
                            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                <Button variant="contained" sx={{
                                    textAlign: 'center',
                                    marginTop: '48px',
                                    marginBottom: '12px'
                                }} size="large" component={HyperLink} to="/contact">Contact Us</Button>
                            </AnimationOnScroll>
                        </Container>
                    </Grid>
                </Grid>
                <Box sx={{ background: '#eee' }} >
                    <Container maxWidth="lg" sx={{ paddingTop: '48px', textAlign: 'center' }}>
                        <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                            <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingBottom: '16px' }}>
                                WHY CHOOSE TAS PETROLEUM?
                            </Typography>
                        </AnimationOnScroll>
                    </Container>
                </Box>
                <Grid container rowSpacing={5} sx={{ textAlign: 'center', color: '#555', background: '#eee', padding: '8px' }}>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                        <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                            <LocalGasStationIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                            <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                                Premium Gas Station Outlets
                            </Typography>
                        </AnimationOnScroll>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                        <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                            <LocalShippingIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                            <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                                On-Demand Delivery to your doorstep
                            </Typography>
                        </AnimationOnScroll>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                        <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                            <OilBarrelIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                            <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                                Premium Lubricants, Solvents and AdBlue®
                            </Typography>
                        </AnimationOnScroll>
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                        <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                            <CardMembershipIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                            <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                                Streamlined Efficiency with Fuel Cards
                            </Typography>
                        </AnimationOnScroll>
                    </Grid>
                    <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                        <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                            <Button variant="contained" sx={{
                                textAlign: 'center',
                                marginTop: '32px',
                                marginBottom: '32px'
                            }} size="large" component={HyperLink} to="/sites">Site Locator</Button>
                        </AnimationOnScroll>
                    </Grid>
                </Grid>
                <Container maxWidth="lg" sx={{ paddingTop: '64px', paddingBottom: '32px', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Typography variant="h4" sx={{ fontWeight: '300' }} gutterBottom className="animate__animated animate__fadeIn">
                            Fuel, Lubricant, Solvent and AdBlue® Partners
                        </Typography>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Grid container spacing={2} maxWidth="lg" sx={{ marginTop: '48px', textAlign: 'center' }}>
                            <Grid sx={logoGridStyle}>
                                <Box sx={{ width: '164px', marginLeft: '16px', marginRight: '16px', marginTop: '8px' }} component="img" alt="CaltexLogo" src={CaltexLogo} />
                            </Grid>
                            <Grid sx={logoGridStyle}>
                                <Box sx={{ width: '64px', marginLeft: '16px', marginRight: '16px' }} component="img" alt="ShellLogo" src={ShellLogo} />
                            </Grid>
                            <Grid sx={logoGridStyle}>
                                <Box sx={{ width: '110px', marginLeft: '16px', marginRight: '16px', marginTop: '7px' }} component="img" alt="MobilLogo" src={MobilLogo} />
                            </Grid>
                            <Grid sx={logoGridStyle}>
                                <Box sx={{ width: '128px', marginLeft: '16px', marginRight: '16px' }} component="img" alt="OilchemLogo" src={OilchemLogo} />
                            </Grid>
                            <Grid sx={logoGridStyle}>
                                <Box sx={{ width: '64px', marginLeft: '16px', marginRight: '16px' }} component="img" alt="NKLogo" src={NKLogo} />
                            </Grid>
                            <Grid sx={logoGridStyle}>
                                <Box sx={{ width: '148px', marginLeft: '16px', marginRight: '16px', marginTop: '8px' }} component="img" alt="TasBlueLogo" src={TasBlueLogo} />
                            </Grid>
                            <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                                <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                                    <Button variant="contained" sx={{
                                        textAlign: 'center',
                                        marginTop: '32px',
                                        marginBottom: '32px'
                                    }} size="large" component={HyperLink} to="/products/lubesearch">Product Finder</Button>
                                </AnimationOnScroll>
                            </Grid>
                        </Grid>
                    </AnimationOnScroll>
                </Container>
            </ThemeProvider>
        </>
    );
}