import { useTheme } from "@mui/material/styles";
import { Box, Breadcrumbs, Button, Card, CardContent, Container, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import Banner from "./banner";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import { Link as HyperLink } from 'react-router-dom';
import ImgDistribute from "../../assets/bg/distribute.jpg";
import MarkerPin from "../../assets/marker.png";
import { useEffect } from "react";
import 'leaflet/dist/leaflet.css';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';

const bgImgStyle = {
    backgroundImage: `url(${ImgDistribute})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%', paddingBottom: '48px'
}

export default function Contact() {
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const MarkerPinIcon = new L.Icon({
        iconUrl: MarkerPin,
        iconSize: [32, 32],
        iconAnchor: [5, 30]
      });

    return (
        <>
            <Banner />
            <Grid container spacing={2}>
                <Grid item md={12} sx={{
                    width: '100%', background: '#004090', color: 'white'
                }}>
                    <Container maxWidth="lg" sx={{ paddingTop: '32px', marginBottom: '48px' }}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '32px', color: 'white' }} separator={<ArrowRightIcon />}>
                            <Link underline="hover" href="/" color='#ccc'>
                                Home
                            </Link>
                            <Typography color="#aaa">Contact Us</Typography>
                        </Breadcrumbs>
                        <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingBottom: '24px' }}>
                            Get in touch with us today!
                        </Typography>
                        <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            textAlign: 'justify'
                        }}>
                            We value your feedback, inquiries, and opportunities to serve you better. Our dedicated team is here to assist you with any questions or concerns you may have. Feel free to reach out to us using the contact information provided below. We look forward to hearing from you!
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
            <Container maxWidth="lg" sx={{ paddingTop: '8px', marginBottom: '48px', marginTop: '32px' }}>
                <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingBottom: '24px', color: '#004090' }}>
                    Our Office:
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xl={6} sm={12} sx={{
                        width: '100%',
                    }}>
                        <Card sx={{
                            boxShadow: 2,
                            transform: 'scale(1, 1)',
                            transition: '500ms',
                            '&:hover': {
                                transform: 'scale(1.02, 1.02)'
                            },
                            background: '#00409005'
                        }}>
                            <CardContent>
                                <Typography variant="h5" component="div" sx={{ color: '' }}>
                                    Launceston
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                                    58 Goderich Street, Invermay, Tasmania 7248
                                </Typography>
                                <Button href="tel:+61363314155" startIcon={<PhoneIcon />} sx={{ fontSize: '18px', marginTop: '8px', color: '#004090' }} size="medium">
                                    +61 3 6331 4155
                                </Button><br />
                                <Button href="mailto:office@taspetroleum.com.au" startIcon={<EmailIcon />} sx={{ textTransform: 'none', fontSize: '18px', marginTop: '0px', color: '#004090' }} size="medium">
                                    office@taspetroleum.com.au
                                </Button>
                            </CardContent>
                        </Card><br />
                        <Card sx={{
                            boxShadow: 2,
                            transform: 'scale(1, 1)',
                            transition: '500ms',
                            '&:hover': {
                                transform: 'scale(1.02, 1.02)'
                            },
                            background: '#00409005'
                        }}>
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    Hobart
                                </Typography>
                                <Typography variant="h6" sx={{ fontWeight: 300 }}>
                                    121 Glenstone Road Bridgewater, Tasmania 7030
                                </Typography>
                                <Button href="tel:+61362635756" startIcon={<PhoneIcon />} sx={{ fontSize: '18px', marginTop: '8px', color: '#004090' }} size="medium">
                                    +61 3 6263 5756
                                </Button><br />
                                <Button href="mailto:reception@taspetroleum.com.au" startIcon={<EmailIcon />} sx={{ textTransform: 'none', fontSize: '18px', marginTop: '0px', color: '#004090' }} size="medium">
                                    reception@taspetroleum.com.au
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xl={6} sm={12} sx={{
                        width: '100%',
                    }}>
                        <MapContainer center={[-42.157, 146.536]} zoom={7} scrollWheelZoom={false} style={{ height: "425px", width: "100%" }}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={[-41.42633252444561, 147.13179141255335]} icon={MarkerPinIcon}>
                                <Popup>
                                    <b>Launceston</b><br/>
                                    58 Goderich Street, Invermay, Tasmania 7248
                                </Popup>
                            </Marker>
                            <Marker position={[-42.721559253764745, 147.23220636323788]} icon={MarkerPinIcon}>
                                <Popup>
                                    <b>Hobart</b><br/>
                                    121 Glenstone Road Bridgewater, Tasmania 7030
                                </Popup>
                            </Marker>
                        </MapContainer>
                    </Grid>
                </Grid>
                <Box sx={{ marginBottom: '48px' }}>
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '48px' }}>
                        To know more about our site locations and facilities, please visit Site Locations page.
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<NotListedLocationIcon />} component={HyperLink} to="/sites">
                        Site Locations
                    </Button>
                </Box>
            </Container>
        </>
    );
}