import { AppBar, IconButton, Toolbar, Box, Link } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';
import { useUIContext } from "./context";
import Logo from "../../assets/logos/logow.png";

export default function AppbarMobile() {
  const { setDrawerOpen } = useUIContext();
  const appbarStyle = {
    background: '#004090'
  };

  const appbarLink = {
    width: { sm: '160px', xs: '144px'},
    marginLeft: 'auto',
  };

  const appbarLogo = {
    width: { sm: '160px', xs: '144px'},
    marginLeft: 'auto',
    transform: 'Translate(13%)'
  };
  
  return (
    <>
      <AppBar position="fixed" sx={appbarStyle}>
        <Toolbar>
          <Link href="/" sx={appbarLink}>
            <Box sx={{ ...appbarLogo }}
              component="img"
              alt="Logo"
              src={Logo}
            />
          </Link>

          <IconButton onClick={() => setDrawerOpen(true)}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{marginLeft: "auto"}}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    </>
  );
}