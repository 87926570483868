import { Box, Button, CssBaseline, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import BannerBG from "../../assets/bg/mobileBanner.jpg";
import { Link as HyperLink } from 'react-router-dom';
import { useEffect } from "react";

const overlay = {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    background: "#000",
    opacity: '0.75',
    overflow: 'hidden',
};

const bannerWrapper = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
};

const bannerStyle = {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh'
};

const bannerCaption = {
    fontSize: '12vh',
    fontFamily: 'home-caption',
    color: 'white',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
};

const bannerTagline = {
    fontSize: '2.5vh',
    fontFamily: 'roboto',
};

export default function PageNotFound() {
    const theme = useTheme();
    const matchesMedium = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <CssBaseline>
            <Box sx={overlay} />
            <Box sx={bannerWrapper}>
                <Box
                    component="img"
                    sx={bannerStyle}
                    alt="Banner"
                    src={BannerBG}
                />
            </Box>
            <Box sx={bannerCaption}>
                <Box className="animate__animated animate__fadeIn">404</Box>
                <Box className="animate__animated animate__fadeIn" sx={bannerTagline}>Lost in the digital wilderness!<br /><br />
                The page you seek is not available. Please double-check the URL or return to our homepage to explore anew.
                <br /><br />
                <Button className="animate__animated animate__fadeIn animate__delay-1s" variant="contained" size={matchesMedium? 'small' : 'large'}
                 component={HyperLink} to="/">Go To Home Page</Button>
                </Box>
            </Box>
        </CssBaseline>
    );
}