import { useEffect } from "react";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import {
    responsiveFontSizes,
    ThemeProvider,
    useTheme
} from '@mui/material/styles';
import "animate.css/animate.min.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

import BannerBG from "../../assets/products/delivery.jpg";
import Banner from "./banner";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AnimationOnScroll } from "react-animation-on-scroll";

import Slide1 from "../../assets/slides/delivery/delivery1.jpg";
import Slide2 from "../../assets/slides/delivery/delivery2.jpg";
import Slide3 from "../../assets/slides/delivery/delivery3.jpg";
import Slide4 from "../../assets/slides/delivery/delivery4.jpg";
import Slide5 from "../../assets/slides/delivery/delivery5.jpg";
import Slide6 from "../../assets/slides/delivery/delivery6.jpg";
import Slide7 from "../../assets/slides/delivery/delivery7.jpg";
import Slide8 from "../../assets/slides/delivery/delivery8.jpg";
import Slide9 from "../../assets/slides/delivery/delivery9.jpg";
import Slide10 from "../../assets/slides/delivery/delivery10.jpg";
import Slide11 from "../../assets/slides/delivery/delivery11.jpg";
import Slide12 from "../../assets/slides/delivery/delivery12.jpg";
import Slide13 from "../../assets/slides/delivery/delivery13.jpg";

export default function Delivery() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const matchesXL = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesL = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const slides = [Slide1, Slide2, Slide3, Slide4, Slide5, Slide6, Slide7, Slide8, Slide9, Slide10, Slide11, Slide12, Slide13];

    const slideCount = (() => {
        if (matchesMD) {
            return 1;
        } else if (matchesL) {
            return 2;
        } else if (matchesXL) {
            return 2;
        } else {
            return 3;
        }
    });

    const sliderStyle = {
        objectFit: 'cover',
        height: '400px !important',
        width: '100%'
    }

    return (
        <>
            <Banner text={<>Fuel Delivery</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="#004090" href="/products">
                        Products & Services
                    </Link>
                    <Typography color="text.primary">Fuel Delivery</Typography>
                </Breadcrumbs>
                <Box sx={{ paddingBottom: '24px', paddingTop: '16px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', fontWeight: '300' }}>
                        Delivering Fuel in Bulk: Unparalleled Service and Reliability<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        As a market leader among wholesale fuel distributors, we take immense pride in our ability to provide exceptional bulk fuel delivery services.
                        With unwavering commitment, we ensure that fuel reaches our customers precisely when and where they need it.
                        <br /><br />
                        Experience the difference of our unparalleled service and reliability. Trust us to fulfill your bulk fuel requirements with utmost efficiency and
                        convenience.<br /><br />
                    </Typography>
                </Box>
            </Container>

            <Swiper
                slidesPerView={slideCount()}
                spaceBetween={2}
                loop={true}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}>

                {slides.map((item, index) => (
                    <SwiperSlide key={index} >
                        <Box sx={sliderStyle} component="img" alt="Logo" src={item} />
                    </SwiperSlide>
                ))}
            </Swiper>

            <Container className="animate__animated animate__fadeIn">
                <Box sx={{ paddingBottom: '24px', paddingTop: '32px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', fontWeight: '300', color: '#004090' }}>
                        Anywhere, Anytime: Unleash the Power of On-Demand Bulk Delivery!<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        From the remotest corners to bustling retail centers, from expansive farmlands to luxurious yachts, our on-demand bulk delivery service goes the distance to fuel your operations. We pride ourselves on reaching even the most challenging locations with ease.
                        <br /><br />
                        Whether you're in retail, commercial, farming, or any other industry, we are your trusted partner for efficient and timely fuel delivery. No matter the scale or complexity, our dedicated team is ready to cater to your unique requirements.
                        <br /><br />
                        Experience the freedom of fueling your ambitions wherever you are. With our seamless on-demand service, you can focus on what truly matters while we handle the logistics. Embrace the power of convenience and reliability with our unmatched bulk delivery expertise.
                        <br /><br /></Typography>
                </Box>
            </Container>

            <Grid container rowSpacing={5} sx={{ color: '#555', background: '#eee', marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Container className="animate__animated animate__fadeIn">
                        <Box sx={{ paddingBottom: '48px' }}>
                            <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                On-Demand Fuel Delivery: Your Site, Your Schedule!<br /><br />
                            </Typography>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                                Experience the convenience of fuel delivered straight to your site exactly when you need it. Whether you're in retail, commercial, farming, or any other industry, our reliable bulk delivery service has got you covered.
                                <br /><br />
                                Our extensive fleet of tankers operates statewide, ensuring prompt and efficient fuel delivery right to your doorstep. With a minimum delivery quantity of just 1,000 litres, we cater to businesses of all sizes.
                                <br /><br />
                                For larger customers, our Tas Petroleum self-bunded fuel storage tanks provide the ultimate solution. These on-site tanks offer a cost-effective and compliant method of storing bulk fuel, giving you the flexibility, convenience, and security you deserve.
                                <br /><br />
                                Choose our bulk delivery service and fuel your operations with reliability, efficiency, and peace of mind.
                            </Typography>
                        </Box>
                    </Container>
                </Grid>
            </Grid>

            <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                <Container className="animate__animated animate__fadeIn">
                <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                        To know more about our bulk delivery service, please feel free to reach out to us. We are here to assist you.
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<SupportAgentIcon />} component={HyperLink} to="/contact">
                        Contact Us
                    </Button>
                </Container>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '48px' }}>
                    <Button component={HyperLink} to="/products" variant="contained" sx={{
                        textAlign: 'center',
                        marginBottom: '64px'
                    }} size="large" startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Container>
            </AnimationOnScroll>
        </>
    );
}