import { useTheme } from "@mui/material/styles";
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import Banner from "./banner";
import { AnimationOnScroll } from "react-animation-on-scroll";
import FacebookIcon from '@mui/icons-material/Facebook';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import SafetyIcon from '@mui/icons-material/SafetyCheckTwoTone';
import PricingIcon from '@mui/icons-material/MonetizationOnTwoTone';
import ServiceIcon from '@mui/icons-material/CategoryTwoTone';
import QualityIcon from '@mui/icons-material/VerifiedTwoTone';
import SupportIcon from '@mui/icons-material/HandshakeTwoTone';
import ReliableIcon from '@mui/icons-material/TrendingUpTwoTone';
import { Link as HyperLink } from 'react-router-dom';
import ImgDistribute from "../../assets/bg/distribute.jpg";
import { useEffect } from "react";

const bgImgStyle = {
    backgroundImage: `url(${ImgDistribute})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%', paddingBottom: '48px'
}

export default function About() {
    const theme = useTheme();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner />
            <Grid container spacing={2}>
                <Grid item md={12} sx={{
                    width: '100%',
                }}>
                    <Container maxWidth="lg" sx={{ paddingTop: '32px', marginBottom: '48px' }}>
                        <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                            <Link underline="hover" color="#004090" href="/">
                                Home
                            </Link>
                            <Typography color="text.primary">About Us</Typography>
                        </Breadcrumbs>
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            TASMANIA'S LARGEST PRIVATELY OWNED INDEPENDENT FUEL, SOLVENT, LUBRICANTS AND ADBLUE® DISTRIBUTORS
                        </Typography><br />
                        <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            textAlign: 'justify'
                        }}>
                            TAS Petroleum, a Trailblazer Since 2005. From humble beginnings, we embarked on a mission to revolutionize the fuel industry by delivering
                            high-quality bulk fuel to the productive North East and North West farming community. Our unwavering commitment to excellence has propelled
                            us to become a trusted partner for commercial fuel users across the region.<br /><br />With a focus on reliability, exceptional service, and
                            cutting-edge solutions, TAS Petroleum has become synonymous with fueling success in Tasmania. Join us on this remarkable journey and
                            experience the power of our passion for fueling growth and driving progress.
                        </Typography>
                    </Container>
                </Grid>
                <Grid item md={12} sx={bgImgStyle}>
                    <Container maxWidth="lg" sx={{ marginTop: '32px', color: 'white' }}>
                        <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            fontWeight: '300'
                        }}>
                            OUR MISSION
                        </Typography>
                        <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            fontWeight: '300',
                            textAlign: 'justify', marginBottom: '48px'
                        }}><br />
                            At TAS Petroleum, our mission is to be the leading provider of exceptional fuel solutions in Tasmania.
                            We are committed to delivering reliable, high-quality products and services that empower businesses and communities to thrive.
                            Through innovation, integrity, and sustainable practices, we aim to fuel success, drive progress, and make a positive impact
                            on the lives of our customers and the environment.
                        </Typography>
                        <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            fontWeight: '300',
                            marginTop: '48px'
                        }}>
                            OUR VISION
                        </Typography>
                        <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                            fontWeight: '300',
                            textAlign: 'justify', marginBottom: '16px'
                        }}><br />
                            We envision a future where TAS Petroleum stands as the undisputed leader in Tasmania's fuel industry. With a focus on customer satisfaction,
                            operational excellence, and continuous improvement, we strive to exceed expectations and set new benchmarks for quality and service.
                            By embracing cutting-edge technologies, fostering strong partnerships, and prioritizing sustainability, we aim to shape the future of
                            fueling and contribute to the growth and prosperity of Tasmania and its communities.
                        </Typography>
                    </Container>
                </Grid>
            </Grid>
            <Box sx={{ background: '#eee' }} >
                <Container maxWidth="lg" sx={{ paddingTop: '48px', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingBottom: '16px' }}>
                            CORE BELIEFS
                        </Typography>
                    </AnimationOnScroll>
                </Container>
            </Box>
            <Grid container rowSpacing={5} sx={{ textAlign: 'center', color: '#555', background: '#eee', padding: '8px' }}>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <PricingIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            Competitive Pricing
                        </Typography>
                    </AnimationOnScroll>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <QualityIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            Quality
                        </Typography>
                    </AnimationOnScroll>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <ServiceIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            Service & Flexibility
                        </Typography>
                    </AnimationOnScroll>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <SupportIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            Strong Customer Support
                        </Typography>
                    </AnimationOnScroll>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <ReliableIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            Availability & Reliability
                        </Typography>
                    </AnimationOnScroll>
                </Grid>
                <Grid item xl={2} lg={2} md={4} sm={6} xs={6} sx={{ width: '100%', textAlign: 'center' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <SafetyIcon sx={{ fontSize: '64px', color: '#004090aa', margin: '24px' }} />
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                            Safety
                        </Typography>
                    </AnimationOnScroll>
                </Grid>
                <Grid item sx={{ width: '100%', textAlign: 'center' }}>
                <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingTop: '32px'}}>
                        To get more information about our offers, products and services, please feel free to contact us:
                    </Typography>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Button variant="contained" sx={{
                            textAlign: 'center',
                            marginTop: '16px',
                            marginBottom: '48px'
                        }} size="large" component={HyperLink} to="/contact">
                            Contact Us
                        </Button>
                    </AnimationOnScroll>
                </Grid>
            </Grid>
            <Container maxWidth="lg" sx={{ paddingTop: '48px', paddingBottom: '48px', textAlign: 'center' }}>
                <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                    <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingBottom: '16px' }}>
                        OUR STORY
                    </Typography>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                    <iframe width="380" height="300" src="https://www.youtube.com/embed/BO32v2PkQ0U" title="A Tasmanian Story" 
                    frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    allowFullScreen />
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingTop: '64px', color: '#004090' }}>
                        To get instant news and updates from us, follow us on:
                    </Typography>
                    <Box>
                        <Link sx={{ color: '#004090', textDecoration: 'none' }} href="https://www.facebook.com/Taspetroleum1" target="_blank"><FacebookIcon sx={{ fontSize: '64px' }} /></Link>
                    </Box>
                </AnimationOnScroll>
            </Container>
        </>
    );
}