import { useTheme } from "@mui/material/styles";
import { Box, CssBaseline, Divider, useMediaQuery } from "@mui/material";
import IntroVideo from "../../assets/main_intoduction_video.mp4";
import LogoW from "../../assets/logos/logow.png";
import BannerBG from "../../assets/bg/mobileBanner.jpg";

const overlay = {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    background: "#000",
    opacity: '0.5',
    overflow: 'hidden',
};

const bannerWrapper = {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center'
};

const videoStyle = {
    objectFit: 'cover',
    width: '100vw',
    height: '100vh'
};

const bannerContainer = {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: '100%',
    height: '100%'
};

const bannerLogo = {
    width: '40vh',
    opacity: '0.7'
};

const bannerCaption = {
    fontSize: '10vh',
    fontFamily: 'home-caption',
    color: 'white', 
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center'
};

const bannerTagline = {
    fontSize: '3.85vh',
    fontFamily: 'roboto',
};

const bannerImgStyle = {
    position:'absolute',
    objectFit: 'cover',
    width: '100vw',
    height: '400px',
    overflow: 'hidden'
};

const mobileOverlay = {
    position: 'absolute',
    objectFit: 'cover',
    height: '400px',
    width: '100vw',
    margin: 'auto',
    background: "#000",
    opacity: '0.1',
    zIndex: 1000
};

const bannerImgStyleMobile = {
    objectFit: 'cover',
    width: '100vw',
    height: '400px',
    overflow: 'hidden'
};

const bannerCaptionMobile = {
    fontSize: '52px',
    fontFamily: 'home-caption',
    color: 'white', 
    textAlign: 'center',
    height: '400px',
    position: 'absolute',
    left: 0,
    top: '156px',
    right: 0,
    bottom: 0
};

const bannerTaglineMobile = {
    fontSize: '20px',
    fontFamily: 'roboto',
    marginTop: '12px',
    marginLeft: '8px',
    marginRight: '8px'
};

export default function Banner() {
    const theme = useTheme();
    const matchesMedium = useMediaQuery(theme.breakpoints.down('md'));
    if (matchesMedium === true) {
        return (
            <>
                <Box sx={mobileOverlay} />
                <CssBaseline>
                    <Box
                        component="img"
                        sx={bannerImgStyle}
                        alt="Banner"
                        src={BannerBG}
                    />
                </CssBaseline>
                <Box sx={bannerImgStyleMobile}>
                    <Box sx={bannerCaptionMobile}>
                        <Box className="animate__animated animate__fadeInDown">All Over Tasmania</Box>
                        <Divider variant="middle" color='white' sx={{display: 'flex', width: '350px', margin: 'auto'}} />
                        <Box className="animate__animated animate__fadeInUp" sx={bannerTaglineMobile}>Bringing Local Knowledge And Experience</Box>
                    </Box>
                </Box>
            </>
        );
    }
    else {
        return (
            <>
                <CssBaseline>
                    <Box sx={overlay} />
                    <Box sx={bannerWrapper}>
                    <video style={videoStyle} autoPlay loop muted>
                        <source src={IntroVideo} type='video/mp4' />
                    </video>
                    </Box>
                </CssBaseline>
                <Box sx={bannerContainer}>
                    <Box sx={bannerCaption}>
                        <Box component="img" sx={bannerLogo} alt="Logo" src={LogoW} className="animate__animated animate__fadeInDown" />
                        <Box className="animate__animated animate__fadeInUp animate__delay-1s">All Over Tasmania</Box>
                        <Box className="animate__animated animate__fadeInUp animate__delay-2s" sx={bannerTagline}>Bringing Local Knowledge And Experience</Box>
                    </Box>
                </Box>
            </>
        );
    }
}