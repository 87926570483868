import { useEffect } from "react";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import BannerBG from "../../assets/products/onsite.jpg";
import Banner from "./banner";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function Refuelling() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner text={<>On-Site Refuelling</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="#004090" href="/products">
                        Products & Services
                    </Link>
                    <Typography color="text.primary">On-Site Refuelling</Typography>
                </Breadcrumbs>
                <Box sx={{ paddingBottom: '24px', paddingTop: '16px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', fontWeight: '300' }}>
                        At TAS Petroleum, we redefine On-Site Refuelling for Australian businesses, delivering unmatched reliability and convenience.<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        Our On-Site Refuelling Services ensure that your company always has the fuel it needs, right at your site. Whether you're in a remote location, operating on a dam, managing a yacht, or running a retail, commercial, or farming business, we've got you covered with our on-demand bulk delivery service.<br /><br />
                    </Typography>
                </Box>
            </Container>

            <Grid container rowSpacing={5} sx={{ color: '#555', background: '#eee', marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Container className="animate__animated animate__fadeIn">
                        <Box sx={{ paddingBottom: '48px' }}>
                            <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                Choose TAS Petroleum for On-Site Refuelling and experience:<br /><br />
                            </Typography>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                textAlign: 'justify'
                            }}>
                                <Box component={'ul'} sx={{paddingLeft: '16px', paddingRight: '16px'}}>
                                    <li>
                                        <b>Unrivaled Convenience: </b>Say goodbye to time-consuming trips to fuel stations. With TAS Petroleum, fuel is delivered directly to your location whenever and wherever you need it. No more disruptions to your operations or wasted employee hours. We bring the fuel to you, so you can focus on what matters most.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Cost Savings: </b>Our on-site refuelling service offers cost-effective fuel solutions. By eliminating the need for frequent small-scale purchases, you can take advantage of our bulk delivery service to optimize your fuel costs. Benefit from competitive pricing and efficient fuel management strategies that help you save money and maintain a competitive edge.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Tailored Solutions: </b>We understand that every business is unique, and that's why we customize our on-site refuelling services to meet your specific needs. Whether you require fuel for a small operation or a large fleet, we work closely with you to create a solution that ensures efficiency, productivity, and cost-effectiveness.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Reliable Supply: </b>With TAS Petroleum, you can rely on a consistent and uninterrupted fuel supply. Our extensive fleet of specialized vehicles and state-of-the-art logistics ensure that you never run out of fuel when you need it most. Trust us to keep your operations running smoothly.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Streamlined Administration: </b>Our centralized ordering and delivery process simplifies administrative tasks, minimizing expenses and saving you time.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Flexible Storage Solutions: </b>Say goodbye to costly bulk storage facilities. Our on-demand bulk delivery service eliminates the need for on-site fuel storage, reducing your expenses and maximizing space.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Enhanced Efficiency: </b>With accurate tracking of fuel usage, you can monitor fleet performance and optimize fuel efficiency. Get the most out of your fuel and improve your overall productivity.
                                        <br /><br />
                                    </li>
                                    <li>
                                        <b>Unparalleled Safety: </b>We prioritize safety at every step. Our trained personnel operate our On-Site Refuelling vehicles, ensuring a secure and reliable fueling process. Minimize environmental risks and ensure compliance with industry regulations.
                                    </li>
                                </Box>
                            </Typography>
                        </Box>
                    </Container>
                </Grid>
            </Grid>

            <Container className="animate__animated animate__fadeIn">
                <Box sx={{ paddingBottom: '24px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', paddingTop: '32px', fontWeight: '300', color: '#004090' }}>
                        Why choose TAS Petroleum for On-Site Refuelling?<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                        <b>Timely Deliveries: </b>Our fleet of specialized vehicles guarantees prompt and reliable fuel deliveries, tailored to suit your schedule. We're here to keep your operations running smoothly, six days a week.
                        <br /><br />
                        <b>Product Quality Assurance: </b>Trust in our commitment to delivering top-notch products. We offer a wide range of high-quality fuels and lubricants, including VDA-approved Adblue packages, ensuring the performance and longevity of your equipment.
                        <br /><br />
                        <b>Extensive Coverage: </b>Our On-Site Refuelling services are available across Tasmania. From Hobart to Launceston, Devonport to Burnie, and everywhere in between, we bring our reliable fuel delivery service directly to you.
                    </Typography>
                </Box>
            </Container>

            <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                <Container className="animate__animated animate__fadeIn">
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '8px' }}>
                        To know more about our On-Site Refuelling service, please feel free to reach out to us. We are here to assist you.
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<SupportAgentIcon />} component={HyperLink} to="/contact">
                        Contact Us
                    </Button>
                </Container>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '48px' }}>
                    <Button component={HyperLink} to="/products" variant="contained" sx={{
                        textAlign: 'center',
                        marginBottom: '64px'
                    }} size="large" startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Container>
            </AnimationOnScroll>
        </>
    );
}