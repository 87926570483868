import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Appbar from './components/appbar/index';
import { UIProvider } from "./components/appbar/context";
import AppDrawer from "./components/appDrawer";
import Home from "./components/Home";
import Products from "./components/Products";
import Footer from "./components/footer/footer";
import PageNotFound from "./components/PageNotFound";
import FuelSection from "./components/Products/fuel";
import LubeSearch from "./components/Products/lube";
import AdBlue from "./components/Products/adblue";
import Delivery from "./components/Products/delivery";
import Refuelling from "./components/Products/refuelling";
import FuelCard from "./components/Products/fuelcard";
import About from "./components/about";
import Contact from "./components/Contact";
import Sites from "./components/Sites";

function App() {
  useEffect(() => {
    document.title = "TAS Petroleum";
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <UIProvider>
        <Appbar />
        <AppDrawer />
        <Router>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/home" element={<Home />} />
            <Route exact path="/products" element={<Products />} />
            <Route exact path="/products/fuel" element={<FuelSection />} />
            <Route exact path="/products/lubesearch" element={<LubeSearch />} />
            <Route exact path="/products/adblue" element={<AdBlue />} />
            <Route exact path="/products/delivery" element={<Delivery />} />
            <Route exact path="/products/refuelling" element={<Refuelling />} />
            <Route exact path="/products/fuelcard" element={<FuelCard />} />
            <Route exact path="/sites" element={<Sites />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
        <Footer />
      </UIProvider>
    </>
  );
}

export default App;
