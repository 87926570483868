import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import AppbarDesktop from "./appbarDesktop";
import AppbarMobile from "./appbarMobile";

export default function Appbar() {
  const theme = useTheme();
  const matchesMedium = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <div>
      {matchesMedium ? <AppbarMobile/> : <AppbarDesktop/>}
    </div>
  );
}