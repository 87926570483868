import { useTheme } from "@mui/material/styles";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, Divider, Fab, Grid, Link, Typography, Zoom, useMediaQuery, useScrollTrigger } from "@mui/material";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import BannerBG from "../../assets/bg/productsBanner.jpg";
import Banner from "./banner";
import FuelBG from "../../assets/products/fuel.jpg";
import LubeBG from "../../assets/products/lubricants.jpg";
import AdblueBG from "../../assets/products/adblue.jpg";
import DeliveryBG from "../../assets/products/delivery.jpg";
import OnsiteBG from "../../assets/products/onsite.jpg";
import FuelCardBG from "../../assets/products/fuelcard.jpg";
import { useCallback, useEffect, useRef } from "react";
import { KeyboardArrowUp } from "@mui/icons-material";
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const productList = [
    {
        title: 'Premium Fuels',
        body: `Unleashing Growth: TAS Petroleum's Fuel Service, propelling our journey to Success. 
        Statewide fuel delivery and expanding unattended pumps, empowering convenience.`,
        image: FuelBG,
        link: '/products/fuel',
        linkCaption: 'SEE DETAILS'
    },
    {
        title: 'Lubricants & Solvents',
        body: `Enhanced Performance, Ultimate Protection: Elevate your vehicle's performance with our premium 
        product stocks designed for optimal efficiency and maximum protection. `,
        image: LubeBG,
        link: '/products/lubesearch',
        linkCaption: 'SEARCH'
    },
    {
        title: 'AdBlue®',
        body: `TASBLUE™: Exemplary AdBlue® manufactured with precision and ISO compliance. Our meticulous production 
        process ensures that our AdBlue® product meets the highest standards.`,
        image: AdblueBG,
        link: '/products/adblue',
        linkCaption: 'WHAT IS AdBlue®?'
    },
    {
        title: 'Bulk Fuel Delivery',
        body: `Setting the Benchmark in Bulk Fuel Delivery: As a market leader among wholesale fuel distributors, 
        we specialize in delivering bulk fuel precisely when and where our customers require it.`,
        image: DeliveryBG,
        link: '/products/delivery',
        linkCaption: 'KNOW MORE'
    },
    {
        title: 'On-site Refuelling',
        body: `Efficiency Redefined: Revolutionizing fuel delivery by bringing convenience to your Doorstep! 
        Experience the ultimate time-Saver as our team delivers fuel directly to your location.`,
        image: OnsiteBG,
        link: '/products/refuelling',
        linkCaption: 'KNOW MORE'
    },
    {
        title: 'Fuel Card',
        body: `Effortless Fuel Management: TAS Petroleum's Fuel Cards, simplifying your fueling experience. Enjoy 
        streamlined transactions at any of our locations statewide.`,
        image: FuelCardBG,
        link: '/products/fuelcard',
        linkCaption: 'KNOW MORE'
    }
];

export default function Products() {
    const theme = useTheme();
    const matchesMedium = useMediaQuery(theme.breakpoints.down('md'));

    const trigger = useScrollTrigger({
        threshold: 64,
    });

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 412, behavior: "smooth" })
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Banner text={<>Products & Services</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Typography color="text.primary">Products & Services</Typography>
                </Breadcrumbs>
                <Box sx={{ paddingBottom: '24px', paddingTop: '16px' }}>
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                        As Tasmania's leading provider, we proudly deliver top-quality Fuels, Lubricants, and AdBlue® across the state.
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}><br />
                        Our extensive product range caters to diverse industries, including Automotive, Agricultural, Industrial, Transport, Civil, Mining, Marine, and Aviation.
                        <br /><br />With a commitment to performance and reliability, our Fuels, Lubricants, Solvents, and AdBlue® set the gold standard. Experience competitive prices and
                        exceptional customer service, ensuring you receive the utmost value for your money.<br /><br /><br />
                    </Typography>
                    <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', color: '#004090' }}>
                        EXPLORE OUR PRODUCTS & SERVICES
                    </Typography>
                </Box>
            </Container>
            <Box sx={{ marginBottom: '48px' }}>
                <Container className="animate__animated animate__fadeIn">
                    <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                        {productList.map((item, index) => (
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                <Card sx={{
                                    minWidth: 345,
                                    boxShadow: 8,
                                    transform: 'scale(1, 1)',
                                    transition: '500ms',
                                    '&:hover': {
                                        transform: 'scale(1.05, 1.05)'
                                    }
                                }}>
                                    <CardActionArea component={HyperLink} to={item.link}>
                                        <CardMedia
                                            component="img"
                                            height={matchesMedium ? '250' : '200'}
                                            image={item.image}
                                            title={item.title}
                                        />
                                        <CardContent sx={{ zIndex: '0.1' }}>
                                            <Typography gutterBottom variant="h5" component="div">
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2">
                                                {item.body}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <Divider />
                                    <CardActions>
                                        <Button size="large" href={item.link}>
                                        {item.linkCaption}
                                        </Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ marginTop: '48px' }}>
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                            <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300'}}>
                                Click here to get more information about our site locations.
                            </Typography>
                        </AnimationOnScroll>
                        <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                            <Button variant="contained" startIcon={<NotListedLocationIcon />} sx={{
                                textAlign: 'center',
                                marginTop: '8px',
                            }} size="medium" component={HyperLink} to="/sites">Site Locations</Button>
                        </AnimationOnScroll>
                    </Box>
                </Container>
            </Box>
            <Zoom in={trigger}>
                <Box
                    role="presentation"
                    sx={{
                        position: "fixed",
                        bottom: 96,
                        right: 32,
                        zIndex: 1,
                    }}
                >
                    <Fab
                        onClick={scrollToTop}
                        color="primary"
                        size="large"
                        aria-label="Scroll back to top"
                        title="Scroll back to top"
                    >
                        <KeyboardArrowUp fontSize="large" />
                    </Fab>
                </Box>
            </Zoom>
        </>
    );
}