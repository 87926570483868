import {
    Box,
    Button,
    Drawer,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    useMediaQuery
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { useUIContext } from "../appbar/context";
import Logo from "../../assets/logos/logow.png";
import PhoneIcon from '@mui/icons-material/Phone';

const appbarLogo = {
    width: '128px',
    marginLeft: 'auto',
    transform: 'Translate(25%)'
};

export default function AppDrawer() {
    const { drawerOpen, setDrawerOpen } = useUIContext();
    const navItems = [
        { text: 'Home', href: '/' },
        { text: 'Products & Services', href: '/products' },
        { text: 'Site Locations', href: '/sites' },
        { text: 'About Us', href: '/about' },
        { text: 'Contact Us', href: '/contact' },
        { text: 'Customer Portal', href: 'https://taspetroleum.companionsoftware.com.au/' }
    ];
    const theme = useTheme();
    const matchesMedium = useMediaQuery(theme.breakpoints.down('md'));

    if (matchesMedium) {
        return (
            <>
                <Drawer open={drawerOpen} anchor="top" onClose={() => setDrawerOpen(false)} PaperProps={{
                    sx: {
                        backgroundColor: "#004090",
                        color: "white",
                        width: 'auto',
                        height: 'auto'
                    }
                }}>
                    <List>
                        <ListItem>
                            <Box sx={{ ...appbarLogo }}
                                component="img"
                                alt="Logo"
                                src={Logo}
                            />
                            <Button size="small" sx={{
                                color: '#fff',
                                marginLeft: 'auto'
                            }} endIcon={<CloseIcon />}
                                onClick={() => setDrawerOpen(false)}>Close</Button>
                        </ListItem>
                        {navItems.map((item, index) => (
                            <Link key={index} href={item.href} sx={{ textDecoration: 'none', color: 'white' }} target={index === navItems.length - 1 ? '_blank' : ''}
                                onClick={() => setDrawerOpen(false)}>
                                <ListItemButton>
                                    <ListItemText>
                                        {item.text}
                                    </ListItemText>
                                </ListItemButton>
                            </Link>
                        ))}
                        <Link href='tel:+61363314155' sx={{ textDecoration: 'none', color: 'white', display: 'flex' }}
                            onClick={() => setDrawerOpen(false)}>
                            <ListItemButton>
                                <Box sx={{ display: 'flex' }}>
                                    <PhoneIcon />&nbsp;&nbsp;
                                    <Link sx={{ color: 'white', textDecoration: 'none', paddingBottom: '4px' }} href="tel:+61363314155">
                                        Call Us
                                    </Link>
                                </Box>
                            </ListItemButton>
                        </Link>
                    </List>
                </Drawer>
            </>
        );
    }
}
