import * as React from 'react';
import { AppBar, Box, Button, Link, Toolbar, useMediaQuery } from "@mui/material";
import Logo from "../../assets/logos/logo.png";
import { useTheme } from '@mui/material/styles';

export default function AppbarDesktop() {
  const navItems = [
    { text: 'Home', href: '/' },
    { text: 'Products & Services', href: '/products' },
    { text: 'Site Locations', href: '/sites' },
    { text: 'About Us', href: '/about' },
    { text: 'Contact Us', href: '/contact' },
    { text: 'Customer Portal', href: 'https://taspetroleum.companionsoftware.com.au/' }
  ];
  
  let theme = useTheme();
  const matchesMD = useMediaQuery(theme.breakpoints.down('lg'));

  const appbarD = {
    background: 'transparent',
    backgroundImage: 'linear-gradient(240deg, #fff, #ffffffdd)',
    color: 'navy',
    boxShadow: '0',
    transition: 'background .5s linear',
  };

  const appbarLogo = {
    width: { md: '128px', lg: '144px', xl: '160px' },
    marginTop: { xl: '8px' },
    marginBottom: { xl: '8px' }
  };

  const appbarButton = {
    color: '#004090',
    textDecoration: 'none'
  };

  return (
    <>
      <AppBar component="nav" sx={appbarD}>
        <Toolbar>
          <Link href="/" sx={appbarButton}>
            <Box
              component="img"
              sx={appbarLogo}
              alt="Logo"
              src={Logo}
            />
          </Link>
          <Box sx={{ display: { xs: 'none', sm: 'block' }, marginLeft: 'auto' }}>
            {navItems.map((item, index) => (
              <Button href={item.href} key={index} size={matchesMD? 'medium' : 'large'} target={index===navItems.length-1 ? '_blank' : ''} sx={appbarButton}>
                {item.text}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}