import { useEffect } from "react";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import BannerBG from "../../assets/products/bg_adblue.jpg";
import Banner from "./banner";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TasBlueLogo from "../../assets/logos/tasblueLogo.png";
import tasblueBarrel from "../../assets/products/adblue_main_img.png";
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function AdBlue() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner text={<>AdBlue ®</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="#004090" href="/products">
                        Products & Services
                    </Link>
                    <Typography color="text.primary">AdBlue®</Typography>
                </Breadcrumbs>
                <Box sx={{ paddingBottom: '24px', paddingTop: '16px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', fontWeight: '300' }}>
                        AdBlue® is otherwise known as Diesel exhaust fluid (DEF)<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        AdBlue® is a high-quality urea-based solution that is specifically designed to reduce harmful emissions in diesel engines equipped with Selective Catalytic Reduction (SCR) technology. By breaking down harmful nitrogen oxides (NOx) into harmless nitrogen and water vapor, AdBlue® helps vehicles comply with stringent emission regulations while minimizing their impact on the environment.
                    </Typography>
                </Box>
            </Container>

            <Grid container rowSpacing={5} sx={{ color: '#555', background: '#eee', marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Container className="animate__animated animate__fadeIn">
                        <Box sx={{ paddingBottom: '24px' }}>
                            <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                Benefits of using AdBlue®:
                            </Typography>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                                <Box component={'ul'} sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Environmental Compliance: </b>
                                            AdBlue® plays a crucial role in reducing nitrogen oxide emissions, a major contributor to air pollution. By using AdBlue®,
                                            you can ensure that your vehicle meets the latest emission standards and helps protect the environment.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Improved Fuel Efficiency: </b>
                                            AdBlue® enables SCR-equipped diesel engines to optimize their performance and fuel efficiency. By effectively reducing NOx emissions,
                                            the engine can operate at its optimal level, resulting in improved fuel economy.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Engine Longevity: </b>
                                            The use of AdBlue® helps to prevent the build-up of harmful deposits in the SCR system, ensuring the longevity and reliability
                                            of your vehicle's engine. It helps maintain the efficiency of the exhaust after-treatment system, reducing the risk of costly
                                            repairs and downtime.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Cost Savings: </b>
                                            AdBlue® can contribute to cost savings in the long run. By complying with emission regulations, you avoid potential penalties
                                            and fines. Additionally, improved fuel efficiency translates to reduced fuel consumption, resulting in lower operational costs.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Sustainability: </b>
                                            Choosing AdBlue® demonstrates your commitment to sustainability and responsible business practices. By actively reducing emissions,
                                            you contribute to a cleaner and healthier environment for future generations.
                                        </Box>
                                    </li>
                                </Box>
                            </Typography>
                        </Box>
                    </Container>
                </Grid>
            </Grid>

            <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '32px' }}>
                <Box sx={{ paddingBottom: '24px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginBottom: '16px' }}>
                        At TAS Petroleum, we take pride in offering our own brand of AdBlue®
                    </Typography>
                    <Box sx={{ width: '256px' }} component="img" alt="TasBlueLogo" src={TasBlueLogo} /><br />
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                        <Box sx={{ color: '#004090' }} component="span"><b>tasblue</b></Box> AdBlue® solution is manufactured to the highest industry standards, ensuring its quality, effectiveness, and compatibility with SCR systems.
                    </Typography><br /><br />
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn">
                        Experience the convenience of finding tasblue at any of our sites. Whether you're in the North East, North West, or any other region, tasblue is stocked and ready to meet your AdBlue® needs. Enjoy the ease of access and peace of mind that comes with our extensive network of locations.
                    </Typography>
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                        Find our nearest sites:
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<NotListedLocationIcon />} component={HyperLink} to="/sites">
                        Site Locations
                    </Button>
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                        <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                            Versatile Packaging and Bulk Supply
                        </Typography>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                        <Box sx={{ width: '128px' }} component="img" alt="tasblueBarrel" src={tasblueBarrel} /><br /><br />
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__fadeIn" offset={64}>
                        <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                            <Box sx={{ color: '#004090' }} component="span"><b>tasblue</b></Box> is available in flexible packaging options, including 20L, 210L, and 1000L packs.<br /><br />For larger quantities,
                            we offer convenient bulk supply starting from a minimum order of 1,000L. Directly sourced from <Box sx={{ color: '#004090' }} component="span">TAS Petroleum</Box>, you can trust in the
                            reliability and convenience of tasblue for all your AdBlue® needs.
                        </Typography>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                            To know more about our products or bulk enquiries, please feel free to reach out to us. We are here to assist you.
                        </Typography>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<SupportAgentIcon />} component={HyperLink} to="/contact">
                            Contact Us
                        </Button>
                    </AnimationOnScroll>
                </Box>
            </Container>
            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '32px' }}>
                    <Button component={HyperLink} to="/products" variant="contained" sx={{
                        textAlign: 'center',
                        marginBottom: '64px'
                    }} size="large" startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Container>
            </AnimationOnScroll>
        </>
    );
}