import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { CssBaseline, ThemeProvider, createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root'));
const themeLight = createTheme({
  // palette: {
  //   background: {
  //     default: "#eaf4f6"
  //   }
  // }
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={themeLight}>
      <CssBaseline>
        <App />
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);