import * as React from 'react';
import { useTheme, responsiveFontSizes } from "@mui/material/styles";
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Typography, useMediaQuery } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import "animate.css/animate.min.css";
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import NearMeTwoToneIcon from '@mui/icons-material/NearMeTwoTone';
import CheckIcon from '@mui/icons-material/Check';
import { Link as HyperLink } from 'react-router-dom';
import MarkerPin from "../../assets/fuelmarker.png";
import { useEffect } from "react";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet';
import TASLogo from "../../assets/logos/sitelogo.png";
import NKEnergyLogo from "../../assets/logos/NKLogo64.png";
import 'leaflet/dist/leaflet.css';
import TASSiteList from './TASSiteList.json';
import TrdPartySiteList from './TrdPartySiteList.json';

export default function Sites() {
    let theme = useTheme();
    theme = responsiveFontSizes(theme);
    const matchesXL = useMediaQuery(theme.breakpoints.down('xl'));
    const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
    const matchesMD = useMediaQuery(theme.breakpoints.down('md'));
    const matchesSM = useMediaQuery(theme.breakpoints.down('sm'));
    const matchesXS = useMediaQuery(theme.breakpoints.down('xs'));

    const mobileView = (() => {
        return matchesXL && matchesLG && matchesMD;
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const MarkerPinIcon = new L.Icon({
        iconUrl: MarkerPin,
        iconSize: [32, 32],
        iconAnchor: [5, 30]
    });

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const styleFeatureBox = { fontSize: '14px', display: 'flex', alignItems: 'center', color: '#555' };

    const styleFeatureIcon = { fontSize: '16px', marginRight: '8px', color: '#004090' };

    const getSiteFeatures = (site) => {
        return (
            <Grid container spacing={0} sx={{ marginTop: '16px', marginBottom: '16px' }}>
                <Grid item xs={6}>
                    {site.products.p91 ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Unleaded</Box>
                        </Box> : null
                    }
                    {site.products.p95 ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Premium 95</Box>
                        </Box> : null
                    }
                    {site.products.p98 ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>{site.owned ? 'Premium 98' : site.isShell ? 'V-Power 98' : 'Premium 98'}</Box>
                        </Box> : null
                    }
                    {site.products.dsl ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Diesel</Box>
                        </Box> : null
                    }
                    {site.products.pdsl ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Premium Diesel</Box>
                        </Box> : null
                    }
                    {site.products.adblue ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>AdBlue®</Box>
                        </Box> : null
                    }
                </Grid>
                <Grid item xs={6}>
                    {site.features.shellCards ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Shell Card</Box>
                        </Box> : null
                    }
                    {site.features.fuelCard ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>TAS Petroleum Card</Box>
                        </Box> : null
                    }
                    {site.features.eftpos ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>EFT PoS</Box>
                        </Box> : null
                    }
                    {site.features.unmanned !== 'No' && site.address!== 'Coming soon' ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>{site.features.unmanned}</Box>
                        </Box> : null
                    }
                    {site.features.truckAccess ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Truck Access</Box>
                        </Box> : null
                    }
                    {site.features.toilet ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Toilets</Box>
                        </Box> : null
                    }
                    {site.features.workshop ?
                        <Box sx={styleFeatureBox}>
                            <CheckIcon sx={styleFeatureIcon} />
                            <Box>Workshop</Box>
                        </Box> : null
                    }
                </Grid>
            </Grid>
        );
    }

    const getMobileSiteList = () => {
        return TASSiteList.map((site, index) => {
            return (
                <Accordion expanded={expanded === 'site' + index} onChange={handleChange('site' + index)} key={'site' + index}
                    sx={{ marginTop: '8px', boxShadow: 1, background: '#fbfbfb' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={'site' + index + 'bh-content'}
                        id={'site' + index + 'bh-header'}
                    >
                        <Grid container spacing={0}>
                            {
                                site.owned ? (<>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="TASLogo" src={TASLogo} />
                                        {
                                            site.nkEnergy ?
                                                <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="NKEnergyLogo" src={NKEnergyLogo} /> : null
                                        }
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                    </Grid>
                                </>
                                ) : (
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sx={{ paddingTop: '8px' }}>
                                <Typography variant="p" sx={{ color: '#444' }}>{site.address}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: 0 }}>
                        <Divider />
                        {getSiteFeatures(site)}
                        {site.address !== 'Coming soon' ?
                            <Button variant="contained" size="small" startIcon={<NearMeTwoToneIcon />} component={HyperLink} target='_blank'
                                to={'https://www.google.com/maps/dir/?api=1&origin=&destination=' + site.map.lat + ',' + site.map.lng}>
                                Navigate to this
                            </Button> : null}
                    </AccordionDetails>
                </Accordion>
            );
        });
    }

    const getDesktopSiteList = () => {
        return (TASSiteList.map((site, index) => {
            return (
                <Grid item md={6}>
                    <Accordion expanded={expanded === 'site' + index} onChange={handleChange('site' + index)} key={'site' + index}
                        sx={{ marginTop: '8px', background: '#fbfbfb' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={'site' + index + 'bh-content'}
                            id={'site' + index + 'bh-header'}
                        >
                            <Grid container spacing={0}>
                                {
                                    site.owned ? (<>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="TASLogo" src={TASLogo} />
                                            {
                                                site.nkEnergy ?
                                                    <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="NKEnergyLogo" src={NKEnergyLogo} /> : null
                                            }
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                        </Grid>
                                    </>
                                    ) : (
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sx={{ paddingTop: '8px' }}>
                                    <Typography variant="p" sx={{ color: '#444' }}>{site.address}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingTop: 0 }}>
                            <Divider />
                            {site.address !== 'Coming soon' ?
                                <Button variant="outlined" size="small" startIcon={<NearMeTwoToneIcon />} component={HyperLink} target='_blank'
                                    to={'https://www.google.com/maps/dir/?api=1&origin=&destination=' + site.map.lat + ',' + site.map.lng}
                                    sx={{ marginTop: '12px', color: '#004090', borderColor: '#004090' }}>
                                    View in Map
                                </Button> : null
                            }
                            {getSiteFeatures(site)}
                        </AccordionDetails>
                    </Accordion>
                </Grid>);
        }));
    }

    const get3rdPartyMobileSiteList = () => {
        return TrdPartySiteList.map((site, index) => {
            return (
                <Accordion expanded={expanded === 'tsite' + index} onChange={handleChange('tsite' + index)} key={'tsite' + index}
                    sx={{ marginTop: '8px', boxShadow: 1, background: '#fbfbfb' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={'tsite' + index + 'bh-content'}
                        id={'tsite' + index + 'bh-header'}
                    >
                        <Grid container spacing={0}>
                            {
                                site.owned ? (<>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="TASLogo" src={TASLogo} />
                                        {
                                            site.nkEnergy ?
                                                <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="NKEnergyLogo" src={NKEnergyLogo} /> : null
                                        }
                                    </Grid>
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                    </Grid>
                                </>
                                ) : (
                                    <Grid item md={12} sm={12} xs={12}>
                                        <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                    </Grid>
                                )
                            }
                            <Grid item xs={12} sx={{ paddingTop: '8px' }}>
                                <Typography variant="p" sx={{ color: '#444' }}>{site.address}</Typography>
                            </Grid>
                        </Grid>
                    </AccordionSummary>
                    <AccordionDetails sx={{ paddingTop: 0 }}>
                        <Divider />
                        {
                            site.branding !== null || site.branding !== undefined ?
                                <Grid container spacing={0} sx={{ fontSize: '14px', marginTop: '16px', marginBottom: '16px', color: '#666' }}>{site.branding}</Grid>
                                : null
                        }
                        {site.address !== 'Coming soon' ? getSiteFeatures(site) : null}
                        {site.address !== 'Coming soon' ?
                            <Button variant="contained" size="small" startIcon={<NearMeTwoToneIcon />} component={HyperLink} target='_blank'
                                to={'https://www.google.com/maps/dir/?api=1&origin=&destination=' + site.map.lat + ',' + site.map.lng}>
                                Navigate to this
                            </Button> : null}
                    </AccordionDetails>
                </Accordion>
            );
        });
    }

    const get3rdPartyDesktopSiteList = () => {
        return (TrdPartySiteList.map((site, index) => {
            return (
                <Grid item md={6}>
                    <Accordion expanded={expanded === 'tsite' + index} onChange={handleChange('tsite' + index)} key={'tsite' + index}
                        sx={{ marginTop: '8px', background: '#fbfbfb' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls={'tsite' + index + 'bh-content'}
                            id={'tsite' + index + 'bh-header'}
                        >
                            <Grid container spacing={0}>
                                {
                                    site.owned ? (<>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="TASLogo" src={TASLogo} />
                                            {
                                                site.nkEnergy ?
                                                    <Box sx={{ height: '24px', marginRight: '12px' }} component="img" alt="NKEnergyLogo" src={NKEnergyLogo} /> : null
                                            }
                                        </Grid>
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                        </Grid>
                                    </>
                                    ) : (
                                        <Grid item md={12} sm={12} xs={12}>
                                            <Typography variant="h6" sx={{ width: '100%', color: '#004090' }}>{site.name}</Typography>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} sx={{ paddingTop: '8px' }}>
                                    <Typography variant="p" sx={{ color: '#444' }}>{site.address}</Typography>
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails sx={{ paddingTop: 0 }}>
                            <Divider />
                            {site.address !== 'Coming soon' ?
                                <Button variant="outlined" size="small" startIcon={<NearMeTwoToneIcon />} component={HyperLink} target='_blank'
                                    to={'https://www.google.com/maps/dir/?api=1&origin=&destination=' + site.map.lat + ',' + site.map.lng}
                                    sx={{ marginTop: '12px', color: '#004090', borderColor: '#004090' }}>
                                    View in Map
                                </Button> : null
                            }
                            {
                                site.branding !== null || site.branding !== undefined ?
                                    <Grid container spacing={0} sx={{ fontSize: '14px', marginTop: '16px', marginBottom: '16px', color: '#666' }}>{site.branding}</Grid>
                                    : null
                            }
                            {site.address !== 'Coming soon' ? getSiteFeatures(site) : null}
                        </AccordionDetails>
                    </Accordion>
                </Grid>);
        }));
    }

    return (
        <>
            <Grid container spacing={2} sx={{ paddingTop: mobileView() ? '80px' : '78px' }}>
                {mobileView() ? <Grid item md={12}>
                    <Container maxWidth="lg">
                        <Breadcrumbs aria-label="breadcrumb" separator={<ArrowRightIcon />}>
                            <Link underline="hover" href="/">
                                Home
                            </Link>
                            <Typography>Site Locations</Typography>
                        </Breadcrumbs>
                    </Container>
                    <Container maxWidth="lg" sx={{ paddingTop: '8px', marginTop: '16px' }}>
                        <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                            Site Locations
                        </Typography>
                    </Container>
                </Grid> : null}
                <Grid item xs={12} style={{ marginTop: mobileView() ? '0' : '16px', padding: 0 }}>
                    <MapContainer center={[-42.157, 146.536]} zoom={7} scrollWheelZoom={false} style={{ height: "425px", margin: 0, padding: 0 }}>
                        <TileLayer
                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        />
                        {
                            TASSiteList.map((site, index) => {
                                return (
                                    <Marker position={[site.map.lat, site.map.lng]} icon={MarkerPinIcon} key={'map' + index} title={site.name}>
                                        <Popup>
                                            <Box sx={{ marginBottom: '8px' }}>
                                                <b>{site.name}</b><br />
                                                {site.address}
                                            </Box>
                                            {site.address !== 'Coming soon' ?
                                                <Box to={'https://www.google.com/maps/dir/?api=1&origin=&destination=' + site.map.lat + ',' + site.map.lng} component={HyperLink}
                                                    target='_blank' sx={{ textDecoration: 'none' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <NearMeTwoToneIcon sx={{ fontSize: '12px', marginRight: '4px' }} />Navigate</Box>
                                                </Box> : null}
                                        </Popup>
                                    </Marker>
                                );
                            })
                        }
                        {
                            TrdPartySiteList.map((site, index) => {
                                return (
                                    <Marker position={[site.map.lat, site.map.lng]} icon={MarkerPinIcon} key={'tpmap' + index} title={site.name}>
                                        <Popup>
                                            <Box sx={{ marginBottom: '8px' }}>
                                                <b>{site.name}</b><br />
                                                {site.address}
                                            </Box>
                                            {site.address !== 'Coming soon' ?
                                                <Box to={'https://www.google.com/maps/dir/?api=1&origin=&destination=' + site.map.lat + ',' + site.map.lng} component={HyperLink}
                                                    target='_blank' sx={{ textDecoration: 'none' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                        <NearMeTwoToneIcon sx={{ fontSize: '12px', marginRight: '4px' }} />Navigate</Box>
                                                </Box> : null}
                                        </Popup>
                                    </Marker>
                                );
                            })
                        }
                    </MapContainer>
                </Grid>
            </Grid>
            {
                mobileView() ? null :
                    <Container maxWidth="lg" sx={{ paddingTop: '32px' }}>
                        <Breadcrumbs aria-label="breadcrumb" separator={<ArrowRightIcon />}>
                            <Link underline="hover" href="/">
                                Home
                            </Link>
                            <Typography>Site Locations</Typography>
                        </Breadcrumbs>

                        <Typography variant="h3" gutterBottom className="animate__animated animate__fadeIn"
                            sx={{ fontWeight: '300', paddingTop: '8px', marginTop: '24px', color: '#004090' }} >
                            Site Locations
                        </Typography>
                        <Divider />
                    </Container>
            }
            <Container maxWidth="lg" sx={{ marginBottom: '24px', marginTop: '24px' }}>
                {mobileView() ? getMobileSiteList() : <Grid container spacing={2}>{getDesktopSiteList()}</Grid>
                }
            </Container>
            <Container maxWidth="lg" sx={{ marginTop: '32px' }}>
                <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn"
                    sx={{ fontWeight: '300', padding: '8px 8px 8px 16px', marginBottom: '24px', background: '#004090', color: 'white', borderRadius: '4px' }}>
                    Regional Sites and Resellers:
                </Typography>
                {mobileView() ? get3rdPartyMobileSiteList() : <Grid container spacing={2}>{get3rdPartyDesktopSiteList()}</Grid>}
            </Container>
            <Container maxWidth="lg" sx={{ marginBottom: '48px' }}>
                <Box sx={{ marginBottom: '48px' }}>
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '48px' }}>
                        To know more about our site locations and facilities, please feel free to reach out to us. We are here to assist you.
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<SupportAgentIcon />} component={HyperLink} to="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>
        </>
    );
}
