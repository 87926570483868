import { Box, CssBaseline } from "@mui/material";
import BannerBG from "../../assets/bg/office.jpg";

const bannerImgStyle = {
    position: 'absolute',
    objectFit: 'cover',
    width: '100%',
    height: '400px',
    overflow: 'hidden'
};

const mobileOverlay = {
    position: 'absolute',
    objectFit: 'cover',
    height: '400px',
    width: '100%',
    margin: 'auto',
    background: "#000",
    opacity: '0.1',
    zIndex: 1000
};

const bannerImgStyleMobile = {
    objectFit: 'cover',
    width: '100%',
    height: '400px',
    overflow: 'hidden'
};

const bannerCaptionMobile = {
    fontSize: '52px',
    fontFamily: 'home-caption',
    color: 'white',
    textAlign: 'center',
    height: '200px',
    position: 'absolute',
    left: 0,
    top: '186px',
    right: 0,
    bottom: 0
};
export default function Banner() {
    return (
        <>
            <Box sx={mobileOverlay} />
            <CssBaseline>
                <Box
                    component="img"
                    sx={bannerImgStyle}
                    alt="Banner"
                    src={BannerBG}
                />
            </CssBaseline>
            <Box sx={bannerImgStyleMobile}>
                <Box sx={bannerCaptionMobile}>
                    <Box className="animate__animated animate__fadeIn">Contact Us</Box>
                </Box>
            </Box>
        </>
    );
}