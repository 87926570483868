import * as React from 'react';
import { Box, Container, CssBaseline, Grid, Link, Typography } from "@mui/material";

import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';

export default function Footer() {
    const navItems = [
        { text: 'Home', href: '/' },
        { text: 'Products & Services', href: '/products' },
        { text: 'Site Locations', href: '/sites' },
        { text: 'About Us', href: '/about' },
        { text: 'Contact Us', href: '/contact' }
    ]

    return (
        <CssBaseline>
            <Box sx={{ background: '#004090', position: 'absolute', marginTop: '0', width: '100%' }}>
                <Container maxWidth="lg" sx={{ marginTop: '32px', color: 'white' }}>
                    <Grid container spacing={2} maxWidth="lg">
                        <Grid item md={6} xs={12}>
                            <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                Quick Links
                            </Typography>
                            <ul>
                                {navItems.map((item, index) => (
                                    <li key={index}>
                                        <Link href={item.href} sx={{ color: 'white', textDecoration: 'none', fontWeight: '300' }} >{item.text}</Link>
                                    </li>
                                ))}
                            </ul>
                            <Box>
                                Follow us on:
                            </Box>
                            <Box>
                                <Link sx={{ color: 'white', textDecoration: 'none', fontWeight: '300' }} href="https://www.facebook.com/Taspetroleum1" target="_blank"><FacebookIcon sx={{ fontSize: '48px' }} /></Link>
                            </Box>
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginBottom: '18px' }}>
                                Our Support
                            </Typography>
                            <Box sx={{ display: 'flex' }}>
                                <SupportAgentIcon />&nbsp;&nbsp;
                                <Link sx={{ color: 'white', textDecoration: 'none', paddingBottom: '4px' }} href="tel:+61363314155">
                                    +61 3 6331 4155
                                </Link>
                            </Box>
                            <Link sx={{ color: 'white', textDecoration: 'none', paddingLeft: '32px' }} href="tel:+61362635756">
                                +61 3 6263 5756
                            </Link>
                            <Box sx={{ display: 'flex', paddingTop: '16px' }}>
                                <EmailIcon />&nbsp;&nbsp;
                                <Link sx={{ color: 'white', textDecoration: 'none', paddingBottom: '4px' }} href="mailto:office@taspetroleum.com.au">
                                    office@taspetroleum.com.au
                                </Link>
                            </Box>
                            <Link sx={{ color: 'white', textDecoration: 'none', paddingLeft: '32px' }} href="mailto:reception@taspetroleum.com.au">
                                reception@taspetroleum.com.au
                            </Link>
                            <Box sx={{ paddingTop: '16px' }}>
                                <Link sx={{ color: 'white', textDecoration: 'none', fontWeight: '300' }} href="/">
                                    Reach out to us during weekdays from 8 a.m. to 4:30 p.m. and let our dedicated team assist you.
                                </Link>
                            </Box>
                        </Grid>
                        <Grid item md={12}>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontSize: '12px', fontWeight: '300' }}>
                                Copyright© 2023, TAS Petroleum
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </CssBaseline>
    );
}