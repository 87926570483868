import { useEffect } from "react";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import BannerBG from "../../assets/products/fuel.jpg";
import Banner from "./banner";
import DownloadIcon from '@mui/icons-material/Download';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ulp91 from "../../assets/datasheets/ulp91-tds-retail.pdf";
import pulp95 from "../../assets/datasheets/pulp95-tds-retail.pdf";
import vpower from "../../assets/datasheets/v-power-tds-retail.pdf";
import diesel from "../../assets/datasheets/d10-diesel-tds-retail.pdf";

import ShellLogo from "../../assets/logos/shell-lube-logo.png";
import TASLogo from "../../assets/logos/logo.png";

export default function FuelSection() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner text={<>Premium Fuels</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="#004090" href="/products">
                        Products & Services
                    </Link>
                    <Typography color="text.primary">Premium Fuels</Typography>
                </Breadcrumbs>
                <Box sx={{ width: '64px' }} component="img" alt="ShellLogo" src={ShellLogo} />
                <Box sx={{ width: '250px', marginLeft: '32px' }} component="img" alt="TASLogo" src={TASLogo} />
                <Box sx={{ paddingBottom: '24px', paddingTop: '32px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', paddingBottom: '24px' }}>
                        "YOUR TRUSTED FUEL PARTNER"<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        TAS Petroleum's Catalyst for Growth. Our expansive fuel service has been instrumental in propelling us to our current success. With the ability to deliver top-notch fuel statewide, we go the extra mile by offering the convenience of our ever-expanding network of unattended fuel pumps. Experience seamless refueling, wherever you are in Tasmania, as we continue to revolutionize the fueling experience.
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}><br /><br />
                        Choose TAS Petroleum's Premium Fuel Selection and unlock the true potential of your vehicle. With exceptional performance, engine cleanliness, and fuel system maintenance, our premium fuels are tailored to meet the demands of your high-performance engines. Embrace the power of innovation and fuel your journey with TAS Petroleum.
                        <br /><br /></Typography>
                </Box>
            </Container>
            <Grid container rowSpacing={5} sx={{ color: '#555', background: '#eee', marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Container className="animate__animated animate__fadeIn">
                        <Box sx={{ paddingBottom: '48px' }}>
                            <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{  fontWeight: '300', paddingBottom: '24px' }}>
                                OUR PREMIUM FUELS<br />
                            </Typography>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                textAlign: 'justify'
                            }}>
                                <Typography variant="h6">Unleaded Petrol <Box component="span" m="{1}" sx={{ fontStyle: 'italic' }}>91</Box></Typography>Unleash the potential of your engine with TAS Petroleum's Unleaded Petrol 91. Our exclusive Fuel Economy Formula shields your engine from harmful deposits, ensuring optimal performance and longevity. With variable volatility to tackle winter starts and prevent vapor lock in summer, this fuel meets the requirements of the Australian Fuels Quality Standards Act 2000. Experience the power of a minimum Research Octane Number of 91.
                                <br />
                            </Typography>
                            <Button className="animate__animated animate__fadeIn" size='small' startIcon={<DownloadIcon />} component={HyperLink} to={ulp91} target="_blank">
                                Datasheet
                            </Button><br />
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                textAlign: 'justify'
                            }}><br />
                                <Typography variant="h6">Unleaded Petrol <Box component="span" m="{1}" sx={{ fontStyle: 'italic' }}>95</Box></Typography>Elevate your driving experience with TAS Petroleum's Unleaded Petrol 95. Designed for high-performance vehicles and imported models, this fuel boasts a minimum Research Octane Number of 95. Our advanced performance additive safeguards your engine against deposits, delivering improved fuel economy and reduced maintenance. Unleash the potential of your vehicle with this higher octane fuel.
                                <br />
                            </Typography>
                            <Button className="animate__animated animate__fadeIn" size='small' startIcon={<DownloadIcon />} component={HyperLink} to={pulp95} target="_blank">
                                Datasheet
                            </Button><br />
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                textAlign: 'justify'
                            }}><br />
                                <Typography variant="h6">Unleaded Petrol <Box component="span" m="{1}" sx={{ fontStyle: 'italic' }}>98</Box>
                                </Typography>Experience the pinnacle of performance with TAS Petroleum's Unleaded 98. Crafted in collaboration with Scuderia Ferrari, this 98 octane premium fuel is engineered to optimize engine performance instantly. Embrace the power of 99% identical compounds found in fuel used by Scuderia Ferrari. Unlock unrivaled power and efficiency with every drop.
                                <br />
                            </Typography>
                            <Button className="animate__animated animate__fadeIn" size='small' startIcon={<DownloadIcon />} component={HyperLink} to={vpower} target="_blank">
                                Datasheet
                            </Button><br />
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                                textAlign: 'justify'
                            }}><br />
                                <Typography variant="h6">Diesel</Typography> Power your high-speed diesel engine with TAS Petroleum's Diesel. Specifically designed for engines operating under varying loads and speeds, this ultra-low sulfur diesel fuel excels in modern compression ignition engines. With superior performance and minimal health or safety hazards, TAS Petroleum's Diesel is your trusted companion on the road.
                                <br />
                            </Typography>
                            <Button className="animate__animated animate__fadeIn" size='small' startIcon={<DownloadIcon />} component={HyperLink} to={diesel} target="_blank">
                                Datasheet
                            </Button>
                        </Box>

                    </Container>
                </Grid>
            </Grid>
            <Container className="animate__animated animate__fadeIn">
                <Box sx={{ marginBottom: '48px' }}>
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                        To know more about our site locations and facilities, please visit Site Locations page.
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<NotListedLocationIcon />} component={HyperLink} to="/sites">
                        Site Locations
                    </Button>
                </Box>
                <Button variant="contained" sx={{
                    textAlign: 'center',
                    marginBottom: '64px'
                }} size="large" startIcon={<ArrowBackIcon />} component={HyperLink} to="/products">
                    Back
                </Button>
            </Container>
        </>
    );
}