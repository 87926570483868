import { useEffect } from "react";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Divider, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import BannerBG from "../../assets/products/lubricants.jpg";
import Banner from "./banner";
import DescriptionIcon from '@mui/icons-material/Description';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SearchIcon from '@mui/icons-material/Search';
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import ShellLogo from "../../assets/logos/shell-lube-logo.png";
import MobilLogo from "../../assets/logos/mobile-lube-logo.png";
import Oilchemlogo from "../../assets/logos/oilchemLogo.png";
import CaltexLogo from "../../assets/logos/CaltexH.png";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function LubeSearch() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner text={<>Lubricants & Solvents</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="#004090" href="/products">
                        Products & Services
                    </Link>
                    <Typography color="text.primary">Lube Search</Typography>
                </Breadcrumbs>
                <Box sx={{ paddingBottom: '24px', paddingTop: '16px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', fontWeight: '300' }}>
                        Unlock Peak Performance with our Premium Lubricants and Solvents Collection<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        Discover a comprehensive range of high-quality lubricants and solvents meticulously selected to meet your diverse needs. From industrial machinery to automotive engines, our lubricants provide optimal protection and ensure smooth operation, enhancing performance and extending the lifespan of your equipment.
                    </Typography>
                </Box>
            </Container>


            <Grid container rowSpacing={5} sx={{ color: '#555', background: '#eee', marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Container className="animate__animated animate__fadeIn">
                        <Box sx={{ paddingBottom: '24px' }}>
                            <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                Experience the Difference:
                            </Typography>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                                <ul>
                                    <li><Box sx={{ paddingTop: '8px' }}><b>Unleash Power:</b> Maximize efficiency and power output.</Box></li>
                                    <li><Box sx={{ paddingTop: '8px' }}><b>Superior Protection:</b> Safeguard against corrosion, rust, and oxidation.</Box></li>
                                    <li><Box sx={{ paddingTop: '8px' }}><b>Versatile Performance:</b> Tailored for diverse industries and equipment.</Box></li>
                                    <li><Box sx={{ paddingTop: '8px' }}><b>Precision Engineering:</b> Reliable performance in demanding environments.</Box></li>
                                    <li><Box sx={{ paddingTop: '8px' }}><b>Eco-Conscious Solutions:</b> Sustainable and high-performing options.</Box></li>
                                </ul>
                            </Typography>
                        </Box>
                    </Container>
                </Grid>
            </Grid>

            <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '32px' }}>
                <Box sx={{ paddingBottom: '24px' }}>
                    <Typography variant="h4" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginBottom: '48px' }}>
                        We stock a wide range of lubricants that cover all your requirements.
                    </Typography>

                    <Box sx={{ width: '128px' }} component="img" alt="CaltexLogo" src={CaltexLogo} />
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ color: '#004090' }}>
                        Caltex Lubricants For Businesses:
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                        Caltex is committed to providing the most advanced premium industrial lubricant technologies available for every kind of operation – from mining to agriculture, power generation to construction, trucking to global marine. No matter what challenges you might face, the journey will be Smoother, Better, Together with Caltex.
                    </Typography><br />
                    <Button variant="contained" size="small" startIcon={<SearchIcon />} sx={{ marginTop: '16px' }} component={HyperLink} to="https://www.caltex.com/au/business-solutions/lubricants-finder.html" target="_blank">
                        Search Products
                    </Button><br /><br /><Divider />

                    <Box sx={{ width: '64px', marginTop: '32px' }} component="img" alt="ShellLogo" src={ShellLogo} />
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ color: '#004090' }}>
                        Shell Industrial Lubricants:
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                        Discover the power of Shell's specialized lubricants designed to maximize equipment performance, reduce operating costs, and ensure reliability. Our cutting-edge oils and greases provide superior protection and comply with the latest environmental standards.
                    </Typography><br />
                    <Button variant="contained" size="small" startIcon={<SearchIcon />} sx={{ marginTop: '16px' }} target="_blank" component={HyperLink} to="https://www.shell.com.au/motorists/oils-lubricants/lubematch.html">
                        Search Products
                    </Button><br /><br /><Divider />

                    <Box sx={{ width: '96px', marginTop: '32px' }} component="img" alt="MobilLogo" src={MobilLogo} />
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ color: '#004090' }}>
                        Mobil™ Industrial Lubricants:
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                        Engineered in collaboration with leading equipment builders, Mobil™ lubricants deliver exceptional performance and balanced protection. Experience enhanced efficiency, cost optimization, and improved productivity with our range of viscosity and NLGI penetration grades.
                    </Typography><br />
                    <Button variant="contained" size="small" startIcon={<SearchIcon />} sx={{ marginTop: '16px' }} component={HyperLink} to="http://www.datateck.com.au/lube/mobil_au/Default.asp" target="_blank">
                        Search Products
                    </Button>
                    <Button variant="outlined" size="small" startIcon={<DescriptionIcon />} sx={{ marginTop: '16px', marginLeft: '16px' }} component={HyperLink} to="https://www.msds.exxonmobil.com/IntApps/psims/psims.aspx" target="_blank">
                        Datasheet
                    </Button><br /><br /><Divider />

                    <Box sx={{ width: '128px', marginTop: '32px' }} component="img" alt="Oilchemlogo" src={Oilchemlogo} />
                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ color: '#004090', marginTop: '12px' }}>
                        Oilchem Chemicals:
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                        Oilchem excels as a specialist in handling bulk solvents and chemicals. Our expertise encompasses the secure storage, precise distribution, and meticulous compliance with the Australian Code for the Transport of Dangerous Goods by Road and Rail (ADG Code), pertinent Australian Standards & Codes, and Local Regulations.
                    </Typography><br />
                    <Button variant="contained" size="small" startIcon={<SearchIcon />} sx={{ marginTop: '16px' }} component={HyperLink} to="https://oilchem.com.au/product-category/chemicals/" target="_blank">
                        Search Products
                    </Button>
                    <Button variant="outlined" size="small" startIcon={<DescriptionIcon />} sx={{ marginTop: '16px', marginLeft: '16px' }} component={HyperLink} to="https://www.lupinsys.com/search/oilchem/" target="_blank">
                        Datasheet
                    </Button><br /><br /><Divider />

                    <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                        To discuss bulk enquiries or for any inquiries related to large volume orders, please feel free to reach out to us. We are here to assist you.
                    </Typography>
                    <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<SupportAgentIcon />} component={HyperLink} to="/contact">
                        Contact Us
                    </Button>
                </Box>
            </Container>

            <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '32px' }}>
                <Button variant="contained" sx={{
                    textAlign: 'center',
                    marginBottom: '64px'
                }} size="large" startIcon={<ArrowBackIcon />} component={HyperLink} to="/products">Back</Button>
            </Container>
        </>
    );
}