import { useEffect } from "react";
import { Link as HyperLink } from 'react-router-dom';
import { Box, Breadcrumbs, Button, Container, Grid, Link, Typography } from "@mui/material";
import "animate.css/animate.min.css";
import BannerBG from "../../assets/products/fuelcard.jpg";
import Banner from "./banner";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SupportAgentIcon from '@mui/icons-material/SupportAgentOutlined';
import NotListedLocationIcon from '@mui/icons-material/NotListedLocation';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { AnimationOnScroll } from "react-animation-on-scroll";

export default function FuelCard() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Banner text={<>Fuel Card</>} image={BannerBG} />
            <Container className="animate__animated animate__fadeIn">
                <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '32px', marginBottom: '32px' }} separator={<ArrowRightIcon />}>
                    <Link underline="hover" color="#004090" href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="#004090" href="/products">
                        Products & Services
                    </Link>
                    <Typography color="text.primary">Fuel Card</Typography>
                </Breadcrumbs>
                <Box sx={{ paddingBottom: '24px', paddingTop: '16px' }}>
                    <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ paddingBottom: '24px', fontWeight: '300' }}>
                        With a Fuel Card from TAS Petroleum, you can enjoy a range of benefits that streamline your fuel management process.<br />
                    </Typography>
                    <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn" sx={{
                        textAlign: 'justify'
                    }}>
                        At TAS Petroleum, we recognize the significance of efficient fuel management for businesses. That's why we provide comprehensive fuel card solutions designed to streamline your fuel purchasing and management processes. Our fuel cards offer a range of benefits tailored to suit your business requirements.
                        <br /><br />
                    </Typography>
                </Box>
            </Container>

            <Grid container rowSpacing={5} sx={{ color: '#555', background: '#eee', marginTop: '0px' }}>
                <Grid item xs={12}>
                    <Container className="animate__animated animate__fadeIn">
                        <Box sx={{ paddingBottom: '48px' }}>
                            <Typography variant="h5" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300' }}>
                                Discover why TAS Petroleum fuel cards are the ideal choice for effective fuel management:
                            </Typography>
                            <Typography variant="p" gutterBottom className="animate__animated animate__fadeIn">
                                <Box component={'ul'} sx={{ paddingLeft: '16px', paddingRight: '16px' }}>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Expanded Fuel Station Network: </b>With an extensive network of fuel stations spanning Tasmania, including cities, regional centers, urban
                                            fringes, and even remote areas, TAS Petroleum ensures convenient access to fuel wherever your operations take you.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>24/7 Convenience: </b>Enjoy the convenience of our 24-hour unmanned fuel sites, open round the clock, seven days a week. Additionally,
                                            we offer fully serviced retail sites in Tasmania and Victoria, providing you with flexible refueling options.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Customized Card Solutions: </b>Our fuel card program caters to your unique needs. Whether you're a tradie or a small business owner, our
                                            tailored fuel cards, such as the TAS Petroleum Zoom Card, offer features like fee-free usage, monthly billing, optional odometer readings,
                                            and PIN security for enhanced control and security.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Discounted Fuel Rates: </b>Benefit from discounted fuel prices across our extensive fuel network in Tasmania and Victoria. Our fuel cards ensure
                                            cost savings while maintaining access to both manned and unmanned retail sites.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Robust Reporting and Analytics: </b>Gain valuable insights into your fuel consumption and spending patterns through our advanced reporting and
                                            analytics tools. Track your fuel expenses, monitor usage, and make informed decisions to optimize your fuel budget effectively.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Reliable Customer Support: </b>Our dedicated customer support team is committed to providing prompt assistance. Whether you have questions about
                                            fuel cards, need help with card management, or require detailed reporting, our knowledgeable team is always ready to support you.
                                        </Box>
                                    </li>
                                    <li>
                                        <Box sx={{ paddingTop: '8px' }}>
                                            <b>Expand Your Benefits: </b>In addition to seamless fuel management, TAS Petroleum fuel cards may offer additional value-added services. These
                                            could include discounts on vehicle maintenance or access to vehicle tracking systems, maximizing the benefits of your fuel card investment.
                                        </Box>
                                    </li>
                                </Box>
                            </Typography>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
            <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '32px' }}>
                <Box sx={{ paddingBottom: '24px' }}>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Typography variant="h6" gutterBottom className="animate__animated animate__fadeIn" sx={{ fontWeight: '300', marginTop: '32px' }}>
                            Contact us or visit our nearest fuel station to apply for Your TAS Petroleum Fuel Card.
                        </Typography>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                        <Button variant="outlined" size="small" sx={{ marginTop: '8px' }} startIcon={<NotListedLocationIcon />} component={HyperLink} to="/sites">
                            Site Locations
                        </Button>
                        <Button variant="outlined" size="small" sx={{ marginTop: '8px', marginLeft: '12px' }} startIcon={<SupportAgentIcon />} component={HyperLink} to="/contact">
                            Contact Us
                        </Button>
                    </AnimationOnScroll>
                </Box>
            </Container>
            <AnimationOnScroll animateIn="animate__zoomIn" offset={64}>
                <Container className="animate__animated animate__fadeIn" sx={{ marginTop: '32px' }}>
                    <Button component={HyperLink} to="/products" variant="contained" sx={{
                        textAlign: 'center',
                        marginBottom: '64px'
                    }} size="large" startIcon={<ArrowBackIcon />}>
                        Back
                    </Button>
                </Container>
            </AnimationOnScroll>
        </>
    );
}